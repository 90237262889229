import React from "react";
import RegisterContent from "../../components/actionblock/Register";
import Banner from "../../components/Banner";
import IntroductionContent from "../../components/IntroductionContent";

export default function Register() {
    return (
        <section className="register">
            <Banner component={<RegisterContent />} />

            <IntroductionContent />
        </section>
    );
}
