import React from "react";
import InfoAction from "../components/actionblock/Info";
import Banner from "../components/Banner";
import IntroductionContent from "../components/IntroductionContent";
import { useAuth } from "../../utils/context/authContext";

export default function Home() {
    const { authenticated } = useAuth();

    return (
        <section className="home">
            <Banner
                component={
                    <InfoAction
                        title={authenticated ? "Welkom" : "Meld je aan!"}
                        text={
                            authenticated
                                ? "In het Landelijk Ouderpanel praat je als ouder écht mee over opvoeden en onderwijs. Een paar keer per jaar vragen we naar je mening in een kort online onderzoek.<br/><br/>Hoe meer ouders meedoen, hoe makkelijker we samen dingen kunnen veranderen om het voor gezinnen duidelijker, makkelijker en beter te maken.<br/> <br/>We zullen je binnenkort uitnodigen voor een onderzoek."
                                : "In het Landelijk Ouderpanel praat je als ouder écht mee over opvoeden en onderwijs. Een paar keer per jaar vragen we naar je mening in een kort online onderzoek.<br/><br/>Hoe meer ouders meedoen, hoe makkelijker we samen dingen kunnen veranderen om het voor gezinnen duidelijker, makkelijker en beter te maken.<br/> <br/>Doe je ook mee?"
                        }
                        primaryActionButton={!authenticated ? { text: "Ik wil me aanmelden", link: "/registreren" } : null}
                        secondaryActionText={!authenticated ? "Heb je al een account? Klik dan <a href='/inloggen'>hier om in te loggen</a>" : null}
                    />
                }
            />

            <IntroductionContent />
        </section>
    );
}
