import React from "react";
import { useMutation } from "react-query";
import logo from "../../../../assets/images/logo_lo_color.png";
import { Notify } from "../../../../utils/notify";
import { postForgotPassword } from "../../../../api/api";
import Form from "./Form";
import { useNavigate } from "react-router-dom";

export default function Content() {
    const navigate = useNavigate();
    const forgotPasswordMutation = useMutation((values) => postForgotPassword(values));

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        forgotPasswordMutation.mutate(values, {
            onSuccess: async (data) => {
                Notify.success("Een email is verstuurd als we je emailadres in ons systeem hebben staan!");
                navigate("/inloggen");
                setSubmitting(false);
            },
            onError: (error) => {
                Notify.error("Er is iets misgegaan, probeer het later nogmaals.");
                setSubmitting(false);
            },
        });
    };

    return (
        <div>
            <img className="logo" src={logo} alt="logo" />
            <h1>Wachtwoord vergeten</h1>
            <Form onSubmit={onSubmit} />
        </div>
    );
}
