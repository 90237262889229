import React from "react";
import { Link } from "react-router-dom";
import arrow_left_gray from "../../../assets/images/icons/arrow_left_gray.svg";
import arrow_right_white from "../../../assets/images/icons/arrow_right_white.svg";

export default function Intro({ campaign, nextStep }) {
    return (
        <div>
            <div className="content">
                <h3 className="subtitle">Onderzoek</h3>
                <h1>{campaign?.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: campaign?.description }} />
            </div>
            <div className="buttons_container">
                <Link to={"/"} className="btn kingkong btn-back">
                    <img src={arrow_left_gray} alt="arrow" />
                    <span>Afsluiten</span>
                </Link>
                <button
                    onClick={() => {
                        nextStep();
                    }}
                    className="btn btn-primary kingkong"
                >
                    <span>Volgende</span>
                    <img src={arrow_right_white} alt="arrow" />
                </button>
            </div>
        </div>
    );
}
