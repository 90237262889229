import Noty from 'noty';  
// import "../node_modules/noty/lib/noty.scss";  
// import "../node_modules/noty/lib/themes/mint.scss";  

class Notify {

    static alert(type, text, timeout = 3500) {
        new Noty({
            type: type,
            layout: 'topRight',
            timeout: timeout,
            text: text
        }).show();
    }

    static success(text, timeout = 2500) {
        new Noty({
            type: 'success',
            layout: 'topRight',
            timeout: timeout,
            text: text
        }).show();
    }

    static warning(text, timeout = 3500) {
        new Noty({
            type: 'warning',
            layout: 'topRight',
            timeout: timeout,
            text: text
        }).show();
    }

    static error(text, timeout = 3500) {
        new Noty({
            type: 'error',
            layout: 'topRight',
            timeout: timeout,
            text: text
        }).show();
    }
}

export { Notify };