import React from "react";
import Content from "./Content";

export default function IntroductionContent() {
    return (
        <>
            <Content
                title="Wat is het Landelijk Ouderpanel?"
                text="Het Landelijk Ouderpanel is een online panel waarin ouders hun stem kunnen laten horen. Door mee te doen aan korte onderzoeken over onderwijs en opvoeden krijgen wij een duidelijk beeld van wat jij als ouder belangrijk vindt. Met die informatie kunnen we beter het gesprek aangaan met overheid, politiek en professionals. En verbeteren we, samen met andere partijen, de beschikbare informatie over opvoeden en onderwijs."
            />

            <Content
                title="Hoe werkt het?"
                text="Ouders in het Landelijk Ouderpanel ontvangen een paar keer per jaar per mail of whatsapp een uitnodiging om mee te doen aan een vaak korte onderzoeken over onderwijs of opvoeden. De onderzoeken zijn online, afgestemd op jouw persoonlijke situatie en makkelijk en snel in te vullen. Je beslist zelf of je meedoet en je kunt je op ieder moment weer uitschrijven."
                customClass={"noninitial"}
            />

            <Content
                title="Hoe gaan we om met jouw privacy?"
                text={
                    "Jouw privacy is belangrijk. Uiteraard gaan we vertrouwelijk om met jouw gegevens. We delen deze nooit met derden zonder jouw toestemming. Meer weten? Je leest er alles over in <a href='https://oudersenonderwijs.nl/privacy/' target=_blank>onze privacyverklaring</a>."
                }
                customClass={"noninitial"}
            />

            <Content
                title="Wie zit er achter het Landelijk Ouderpanel?"
                text={
                    "Het Landelijk Ouderpanel is een initiatief van <a href='https://oudersenonderwijs.nl/' target=_blank>Ouders & Onderwijs</a>. Wij zijn er voor ouders. Je kunt gratis bij ons terecht met al je vragen en we praten namens ouders mee over belangrijke thema’s in het onderwijs. Voor de onderzoeken over opvoeden werken we samen met verschillende organisaties. Altijd met het doel om het voor ouders duidelijker, makkelijker en beter te maken."
                }
                customClass={"noninitial"}
            />
        </>
    );
}
