import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo_lo from "../../assets/images/logo_lo_color.png";
import logo_oeno from "../../assets/images/logo_oeno.svg";
import { useAuth } from "../../utils/context/authContext";
import { checkToken } from "../../api/api";
import { useQuery } from "react-query";

export default function Header() {
    const { token, authenticated, logout } = useAuth();
    const [checkTokenEnabled, setCheckTokenEnabled] = useState(false);

    const _ = useQuery(["check-token", token], () => checkToken(), {
        enabled: checkTokenEnabled,
        retry: false,
    });

    useState(() => {
        if (authenticated) {
            setCheckTokenEnabled(true);
        }
    }, [token]);

    return (
        <>
            {authenticated && (
                <header className="sub_header">
                    <div className="container">
                        <p>Je bent ingelogd</p>
                        <div>
                            <Link to="/profiel">
                                <i className="fas fa-user"></i>
                                <span>Mijn account</span>
                            </Link>
                            {/* uitloggen */}
                            <Link onClick={logout} to="/">
                                <span className="logout">Uitloggen</span>
                            </Link>
                        </div>
                    </div>
                </header>
            )}
            <header className={"main_header " + (authenticated ? "authenticated" : "")}>
                <div className="container">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo_lo} alt="Landelijk Ouderpanel" />
                        </Link>
                    </div>
                    <div className="right_side">
                        <p>Een intiatief van</p>
                        <img src={logo_oeno} alt="Ouders en Onderwijs" />
                    </div>
                </div>
            </header>
        </>
    );
}
