import { Field } from "formik";
import React, { useState } from "react";

export default function MultipleAnswer({ name, options, withOther = false }) {
    const [otherSelected, setOtherSelected] = useState(false);

    const otherIndex = options.length;

    return (
        <div className="multiple-answer">
            {options.map((option, index) => (
                <div className="checkbox" key={index}>
                    <Field name={name} value={option.value} id={option.value} type="checkbox" className="form-control" />
                    <label htmlFor={option.value}> {option.label} </label>
                </div>
            ))}
            {withOther && (
                <>
                    <div className="checkbox other" key={otherIndex}>
                        <Field
                            id="other"
                            name="value"
                            value="other"
                            type="checkbox"
                            checked={otherSelected}
                            onChange={(e) => setOtherSelected(!otherSelected)}
                            className="form-control"
                        />
                        <label htmlFor="other mt-1">Anders, namelijk</label>
                    </div>
                    <Field className="other form-control" name={`${name}[${otherIndex}]`} type="text" />
                </>
            )}
        </div>
    );
}
