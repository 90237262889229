import * as Yup from "yup";
import { postEmailAvailable, postPhoneAvailable } from "../api/api";

Yup.setLocale({
    mixed: {
        required: "Dit veld is verplicht.",
    },
});

const basicInfoSchema = (attributes) => {
    let yups = {
        name: Yup.string().nullable().max(50, "De naam mag maximaal 50 karakters bevatten."),
        email: Yup.string()
            .required()
            .email("Vul een geldig e-mailadres in.")
            .test("email", "Dit e-mailadres is al in gebruik.", async (value) => (await postEmailAvailable(value)).available),
        password: Yup.string()
            .required()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/, "Minstens 8 karakters, 1 hoofdletter, 1 nummer en 1 speciaal teken."),
        invitation_method: Yup.string().required().oneOf(["email", "whatsapp"]),
        phone: Yup.string()
            .when("invitation_method", {
                is: "whatsapp",
                then: Yup.string().required(),
                otherwise: Yup.string().nullable(),
            })
            .test("phone", "Dit telefoonnummer is al in gebruik.", async (value) => (await postPhoneAvailable(value)).available),
    };

    return Yup.object().shape(Object.keys(yups).reduce((obj, key) => (attributes.includes(key) ? { ...obj, [key]: yups[key] } : obj), {}));
};

const attributesSchema = (attributes) => {
    let yups = {
        gender: Yup.string().required(),
        zipcode: Yup.string()
            .nullable()
            .test("zipcode", "Vul een geldige postcode in.", (value) => value === "" || (/^\d+$/.test(value) && value >= 1000 && value <= 9999)),
        education: Yup.string().required(),
        born_in_nl: Yup.string().required(),
        parents_born_in_nl: Yup.string().required(),
        income: Yup.string().nullable(),
        children_amount: Yup.string().required(),
        dob_children: Yup.array()
            .min(1, "Dit veld is verplicht.")
            .test("dob_children", "Een van de ingevulde geboortejaren is ongeldig.", (values) =>
                values.every((value) => /^\d+$/.test(value) && value >= new Date().getFullYear() - 23 && value <= new Date().getFullYear())
            ),
        participation_council: Yup.bool().nullable(),
        extra_care_child: Yup.bool().nullable(),
        mbo_child: Yup.bool().nullable(),
        contact_lop: Yup.bool().nullable(),
        newsletter: Yup.bool().nullable(),
        privacy_policy: Yup.bool().required().oneOf([true], "Je moet akkoord gaan met de privacyverklaring."),
    };

    return Yup.object().shape({
        attributes: Yup.object().shape(Object.keys(yups).reduce((obj, key) => (attributes.includes(key) ? { ...obj, [key]: yups[key] } : obj), {})),
    });
};

//Merge multiple schemas into one
const merge = (...schemas) => {
    const [first, ...rest] = schemas;
    const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);
    return merged;
};

export const validateRegistration = async (values, attributes) => {
    await (attributes.length === 0
        ? basicInfoSchema(["name", "email", "password", "invitation_method", "phone"])
        : attributesSchema(attributes)
    ).validate(values, { abortEarly: false });
};

export const validateProfile = async (values, attributes) => {
    //Combine basicInfoSchema and attributesSchema
    await merge(basicInfoSchema(["name", "email", "invitation_method", "phone"]), attributesSchema(attributes)).validate(values, {
        abortEarly: false,
    });
};
