

import React from 'react'
import background from "../../assets/images/background.jpeg";

export default function Banner({ component }) {
    return (
        <section className="banner">
            <div className="bg_image" style={{ backgroundImage: `url('${background}')` }}></div>
            <div className='container'>
                <div className="left_side">
                    <h2 className="handwritten">Laat je stem horen!</h2>
                </div>
                <div className="right_side">
                    <div className="hoverblock">
                        {component}
                    </div>
                </div>
            </div>
        </section>
    )
}
