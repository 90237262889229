import React from "react";

export default function Content({ title, text, customClass }) {
    function createMarkup() {
        return { __html: text };
    }
    return (
        <section className={"info " + customClass}>
            <div className="container">
                <div className="content">
                    <h2>{title}</h2>
                    <p dangerouslySetInnerHTML={createMarkup()}></p>
                </div>
            </div>
        </section>
    );
}
