import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../utils/context/authContext";
import Form from "./parts/Form";
import { fetchUser, putUser } from "../../api/api";
import { Notify } from "../../utils/notify";

export default function Profile() {
    const { token } = useAuth();
    const userMutation = useMutation((values) => putUser(values));

    const userQuery = useQuery(["user", token], () => fetchUser(), {
        retry: 1,
    });

    const [selectedView, setSelectedView] = useState("general");

    const onSubmit = (values, setSubmitting, setErrors) => {
        setSubmitting(true);

        userMutation.mutate(values, {
            onSuccess: (data) => {
                setSubmitting(false);
                userQuery.refetch();
                Notify.success("Gegevens zijn opgeslagen!");
            },
            onError: (error) => {
                if (error.response?.data.errors) {
                    setErrors(error.response.data.errors);
                } else {
                    Notify.error("Het wijzigen is mislukt, probeer het later nogmaals.");
                }
                setSubmitting(false);
            },
        });
    };

    return (
        <section className="profile_section">
            <div>
                <h1>Mijn account</h1>

                {/* two big buttons */}
                <div className="profile_section__big_buttons">
                    <button className={"big_button " + (selectedView === "general" ? "selected" : "")} onClick={() => setSelectedView("general")}>
                        <div className="icon">
                            <i className="fas fa-user"></i>
                        </div>
                        <div className="content">
                            <span>Accountgegevens wijzigen</span>
                            <p>Wijzig hier je gegevens zoals telefoonnummer, e-mail en naam</p>
                        </div>
                    </button>
                    <button
                        className={"big_button " + (selectedView === "attributes" ? "selected" : "")}
                        onClick={() => setSelectedView("attributes")}
                    >
                        <div className="icon">
                            <i className="fas fa-messages-question"></i>
                        </div>
                        <div className="content">
                            <span>Antwoorden aanpassen</span>
                            <p>Is je situatie veranderd? Wijzig hier je antwoorden op vragen over je persoonlijke situatie.</p>
                        </div>
                    </button>
                </div>

                <Form data={userQuery.data} selectedView={selectedView} onSubmit={onSubmit} />
            </div>

            <p className="unsubscribe_text">
                Wil je je uitschrijven voor het Landelijk Ouderpanel? <a href="/uitschrijven">Klik dan hier.</a>
            </p>
        </section>
    );
}
