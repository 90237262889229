import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchCampagin } from "../../api/api";
import Error from "../../components/Error";
import Loading from "../../components/Loading";
import Intro from "./parts/Intro";
import Outro from "./parts/Outro";
import Inactive from "./parts/Inactive";
import Question from "./parts/Question";
import constants from "../../utils/constants";

export default function Campaign() {
    const { token } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [current, setCurrent] = useState(0);
    const [parts, setParts] = useState([]);

    useQuery(["campaign", token], () => fetchCampagin({ token: token }), {
        retry: 1,
        onSuccess: (data) => {
            setParts([
                <Intro campaign={data} nextStep={() => setCurrent(1)} />,
                ...data?.questions.map((question, index) => (
                    <Question question={question} prevStep={() => setCurrent(index)} nextStep={() => setCurrent(index + 2)} number={index + 1} />
                )),
                <Outro campaign={data} prevStep={() => setCurrent(data?.questions?.length)} />,
            ]);
            setLoading(false);
        },
        onError: (error) => {
            if (error.response.data.type === "CAMPAIGN_INACTIVE") {
                setParts([<Inactive />]);
            } else {
                setParts([<Error message={`Er is iets misgegaan, ${constants.CONTACT_OO}`} />]);
            }
            setLoading(false);
        },
    });

    return (
        <section className="campaign">
            <div className="container_sm">{isLoading ? <Loading /> : parts[current] ?? <></>}</div>
        </section>
    );
}
