import { Field, Formik } from "formik";
import React from "react";
import arrow_right_white from "../../../../assets/images/icons/arrow_right_white.svg";
import arrow_left_gray from "../../../../assets/images/icons/arrow_left_gray.svg";
import { Link } from "react-router-dom";
import MultipleAnswer from "../../../../components/MultipleAnswer";

export default function Form({ onSubmit }) {
    const reasons = [
        "Mijn kinderen gaan niet meer naar school",
        "Ik krijg teveel berichten",
        "De onderwerpen zijn niet interessant genoeg",
        "Ik heb er geen tijd (meer) voor",
        "Ik heb er geen zin (meer) in",
    ];

    return (
        <div className="register_form">
            <Formik
                initialValues={{
                    email: "",
                    reasons: [],
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">E-mailadres*</label>
                            <Field name="email" type="email" className="form-control" />
                            {errors.email && touched.email && <div className="error">{errors.email}</div>}
                        </div>

                        <div className="form-group">
                            <label>Waarom schrijf je je uit?</label>
                            <p className="description">Meerdere antwoorden mogelijk</p>
                            <MultipleAnswer name="reasons" options={reasons.map((reason) => ({ label: reason, value: reason }))} withOther={true} />
                            {errors?.attributes?.hasOwnProperty("reasons") && <div className="error">{errors?.attributes["reasons"]}</div>}
                        </div>

                        <div className="buttons_container">
                            <Link type="button" className="btn btn-back kingkong" to="/profiel">
                                <img src={arrow_left_gray} alt="arrow" />
                            </Link>
                            <button type="submit" className="btn btn-primary kingkong" disabled={isSubmitting}>
                                <span>Uitschrijven</span>
                                <img src={arrow_right_white} alt="arrow" />
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}
