import React from "react";
import Banner from "../../components/Banner";
import IntroductionContent from "../../components/IntroductionContent";
import LoginContent from "./parts/Content";

export default function Login() {
    return (
        <section className="register">
            <Banner component={<LoginContent />} />

            <IntroductionContent />
        </section>
    );
}
