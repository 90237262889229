import React, { useState } from "react";
import { useMutation } from "react-query";
import logo from "../../../../assets/images/logo_lo_color.png";
import { Notify } from "../../../../utils/notify";
import { postLogin } from "../../../../api/api";
import Form from "./Form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../utils/context/authContext";

export default function Content() {
    const navigate = useNavigate();
    const { login } = useAuth();
    const loginMutation = useMutation((values) => postLogin(values));

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        loginMutation.mutate(values, {
            onSuccess: async (data) => {
                setSubmitting(false);
                login(data.token);
                navigate("/profiel");
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "login",
                });
            },
            onError: (error) => {
                if (error.response?.data?.errors) {
                    setErrors(error.response?.data?.errors);
                } else if (error.response?.data?.message) {
                    setErrors({ password: error.response.data.message });
                } else {
                    Notify.error("Het inloggen is mislukt, probeer het later nogmaals.");
                }
                setSubmitting(false);
            },
        });
    };

    return (
        <div>
            <img className="logo" src={logo} alt="logo" />
            <h1>Inloggen</h1>
            <Form onSubmit={onSubmit} />
        </div>
    );
}
