import React from "react";
import { Link } from "react-router-dom";

export default function Error({ message }) {
    function createMarkup(text) {
        return { __html: text };
    }

    return (
        <div>
            <h1>Oeps..</h1>
            <p dangerouslySetInnerHTML={createMarkup(message)}></p>
            <Link className="btn btn-primary" to="/">
                Sluiten
            </Link>
        </div>
    );
}
