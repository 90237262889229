import React from "react";
import { useMutation } from "react-query";
import logo from "../../../../assets/images/logo_lo_color.png";
import { Notify } from "../../../../utils/notify";
import { postResetPassword } from "../../../../api/api";
import Form from "./Form";
import { useNavigate } from "react-router-dom";

export default function Content() {
    const navigate = useNavigate();
    const resetPasswordMutation = useMutation((values) => postResetPassword(values));

    const onSubmit = (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        resetPasswordMutation.mutate(values, {
            onSuccess: async (data) => {
                Notify.success("Je wachtwoord is succesvol hersteld!");
                navigate("/inloggen");
                setSubmitting(false);
            },
            onError: (error) => {
                if (error.response?.data.errors) {
                    setErrors(error.response.data.errors);
                } else {
                    Notify.error("Het wijzigen is mislukt, probeer het later nogmaals.");
                }
                setSubmitting(false);
            },
        });
    };

    return (
        <div>
            <img className="logo" src={logo} alt="logo" />
            <h1>Wachtwoord herstellen</h1>
            <Form onSubmit={onSubmit} />
        </div>
    );
}
