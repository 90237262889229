import { Field, Formik } from "formik";
import React from "react";
import arrow_right_white from "../../../../assets/images/icons/arrow_right_white.svg";
import arrow_left_gray from "../../../../assets/images/icons/arrow_left_gray.svg";
import spinner_white from "../../../../assets/images/icons/spinner_white.svg";
import { Link } from "react-router-dom";

export default function Form({ onSubmit }) {
    return (
        <div className="register_form">
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ errors, touched, isSubmitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">E-mailadres *</label>
                            <Field name="email" type="email" className="form-control" />
                            {errors.email && touched.email && <div className="error">{errors.email}</div>}
                        </div>
                        <div className="form-group">
                            <div className="splitted">
                                <label htmlFor="password">Wachtwoord *</label>
                                <a href="/wachtwoord-vergeten" className="forgot_password">
                                    Wachtwoord vergeten?
                                </a>
                            </div>
                            <Field name="password" type="password" className="form-control" />
                            {errors.password && touched.password && <div className="error">{errors.password}</div>}
                        </div>

                        <div className="buttons_container">
                            <Link type="button" className="btn btn-back kingkong" to="/">
                                <img src={arrow_left_gray} alt="arrow" />
                            </Link>
                            <button type="submit" className="btn btn-primary kingkong" disabled={isSubmitting}>
                                {isSubmitting ? (
                                    <div className="spinner">
                                        <img src={spinner_white} alt="spinner" />
                                    </div>
                                ) : (
                                    <>
                                        <span>Inloggen</span>
                                        <img src={arrow_right_white} alt="arrow" />
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}
