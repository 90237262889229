import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { postUnsubscribe } from "../../../api/api";
import Loading from "../../../components/Loading";
import InfoAction from "../../components/actionblock/Info";
import Banner from "../../components/Banner";
import { useAuth } from "../../../utils/context/authContext";

export default function Unsubscribe() {
    const { payload } = useParams();
    const { logout } = useAuth();

    const unsubscribeMutation = useMutation((payload) => postUnsubscribe(payload));

    useEffect(() => {
        unsubscribeMutation.mutate(payload, {
            onSuccess: () => {
                logout();
            },
        });
    }, [payload, unsubscribeMutation, logout]);

    return (
        <section className="unsubscribe">
            {unsubscribeMutation.isLoading ? (
                <Loading />
            ) : (
                <>
                    {unsubscribeMutation.isSuccess ? (
                        <Banner
                            component={
                                <InfoAction
                                    title="Uitschrijving bevestigd"
                                    text="Je ben nu definitief uitgeschreven voor het Landelijk Ouderpanel. Bedankt voor je deelname!"
                                    primaryActionButton={{ link: "/", text: "Naar het Landelijk Ouderpanel" }}
                                />
                            }
                        />
                    ) : (
                        <Banner
                            component={
                                <InfoAction
                                    title="Uitschrijving mislukt"
                                    text="Het uitschrijven is helaas niet gelukt. <a href='https://oudersenonderwijs.nl/contact' target='_blank'>Klik hier</a> om contact met ons op te nemen."
                                    primaryActionButton={{ link: "/", text: "Naar het Landelijk Ouderpanel" }}
                                />
                            }
                        />
                    )}
                </>
            )}
        </section>
    );
}
