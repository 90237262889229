import { Field, Formik } from "formik";
import React from "react";
import arrow_right_white from "../../../../assets/images/icons/arrow_right_white.svg";
import arrow_left_gray from "../../../../assets/images/icons/arrow_left_gray.svg";
import spinner_white from "../../../../assets/images/icons/spinner_white.svg";
import { useQueryParam, StringParam } from "use-query-params";
import { Link } from "react-router-dom";

export default function Form({ onSubmit }) {
    const [token] = useQueryParam("token", StringParam);
    const [email] = useQueryParam("email", StringParam);

    return (
        <div className="register_form">
            <Formik
                initialValues={{
                    email: email,
                    token: token,
                    password: "",
                    password_confirmation: "",
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ errors, touched, isSubmitting, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">E-mailadres</label>
                            <Field disabled name="email" type="email" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Wachtwoord*</label>
                            <p className="description">Minstens 8 karakters, 1 hoofdletter, 1 nummer en 1 speciaal teken.</p>
                            <Field name="password" type="password" className="form-control" required />

                            {errors.password && touched.password && (
                                <>
                                    {errors.password[0] === "passwords.token" ? (
                                        <div className="error">
                                            De link om je wachtwoord aan te maken is verlopen. Ga naar{" "}
                                            <a href="/wachtwoord-vergeten">wachtwoord vergeten</a> om een nieuwe link aan te vragen.
                                        </div>
                                    ) : (
                                        <div className="error">{errors.password}</div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password_confirmation">Herhaal wachtwoord*</label>
                            <Field name="password_confirmation" type="password" className="form-control" required />
                            {errors.password_confirmation && touched.password_confirmation && (
                                <div className="error">{errors.password_confirmation}</div>
                            )}
                        </div>

                        <div className="buttons_container">
                            <Link type="button" className="btn btn-back kingkong" to="/">
                                <img src={arrow_left_gray} alt="arrow" />
                            </Link>
                            <button type="submit" className="btn btn-primary kingkong" disabled={isSubmitting}>
                                {isSubmitting ? (
                                    <div className="spinner">
                                        <img src={spinner_white} alt="spinner" />
                                    </div>
                                ) : (
                                    <>
                                        <span>Wachtwoord aanmaken</span>
                                        <img src={arrow_right_white} alt="arrow" />
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
}
