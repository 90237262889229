import React from "react";
import ReactSelect from "react-select";

export default function Select({ value, name, options, onChange, defaultValue }) {
    return (
        <ReactSelect
            name={name}
            value={value}
            classNamePrefix="custom_select"
            className="custom_select form-control"
            placeholder=""
            onChange={onChange}
            options={options}
            defaultValue={defaultValue}
            noOptionsMessage={() => "Geen opties"}
        />
    );
}
