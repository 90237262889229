// import moment from "moment"

const converters = {
    // convertToDate: (date, options = { year: 'numeric', month: 'long', day: 'numeric' }, locale = 'nl-NL') => {
    //   return new Date(date).toLocaleDateString(locale, options)
    // },

    // convertToDateTime: (date, options = { year: 'numeric', month: 'numeric', day: 'numeric', day: 'numeric', minute: 'numeric', second: 'numeric' }) => {
    //   return new Date(date).toLocaleDateString('nl-NL', options)
    // },

    // convertToDateInput: (date) => {
    //   return moment(date).format("YYYY-MM-DD")
    // },

    // convertToTime: (date, options = { hour: '2-digit', minute: '2-digit' }) => {
    //   return new Date(date).toLocaleTimeString('nl-NL', options)
    // },

    convertToMoney: (number, fixed = 2) => {
        if (!number) {
            return "€0,00";
        }
        number = Math.round(number * 100) / 100;
        if (number === 0 || number % 1 !== 0) {
            number = number.toFixed(2);
        }
        let stringified_number = number.toString();
        return "€" + stringified_number.replace(".", ",");
    },

    convertToPeriod: (start, end, options) => {
        if (start === null && end === null) return "";
        var period = [];
        if (start) {
            period.push(new Date(start).toLocaleDateString("nl-NL", options));
        }
        if (end) {
            if (period.length > 0) period.push(" - ");
            period.push(new Date(end).toLocaleDateString("nl-NL", options));
        }
        return period.join("");
    },

    convertToParams: (data = []) => {
        var params = "";
        for (let [, [key, value]] of Object.entries(data).entries()) {
            if (key == null || value == null || value.length < 1) {
                continue;
            }
            params += key + "=" + encodeURIComponent(value) + "&";
        }
        return ("?" + params).substr(0, params.length);
    },

    firstUppercased: (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
};

export default converters;
