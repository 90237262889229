import axios from "axios";
import converters from "../utils/converters";

const host = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

export const checkToken = () =>
    new Promise((resolve, reject) => {
        return axios
            .get(`${host}/check-token`)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const fetchUser = () =>
    new Promise((resolve, reject) => {
        return axios
            .get(`${host}/user`)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const putUser = (values) =>
    new Promise((resolve, reject) => {
        return axios
            .put(`${host}/user`, values)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const resendEmailVerification = () =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/resend-email-verification`)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const resendPhoneVerification = () =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/resend-phone-verification`)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const fetchCampagin = (values) =>
    new Promise((resolve, reject) => {
        let params = converters.convertToParams(values);
        return axios
            .get(`${host}/campaign${params}`)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const fetchRegisterAttributes = () =>
    new Promise((resolve, reject) => {
        return axios
            .get(`${host}/register/attributes`)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postEmailAvailable = (email) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");

        return axios
            .post(
                `${host}/email/available`,
                { email: email },
                token !== undefined && {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postPhoneAvailable = (phone) =>
    new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");

        return axios
            .post(
                `${host}/phone/available`,
                { phone: phone },
                token !== undefined && {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postRegister = (values) =>
    new Promise((resolve, reject) => {
        let uri = `${host}/register`;
        return axios
            .post(uri, values)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postVerifyEmail = (payload) =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/email/verify/${payload}`)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postVerifyPhone = (payload) =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/p/v/${payload}`)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postLogin = (values) =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/login`, values)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postRequestUnsubscribe = (values) =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/email/unsubscribe/request`, values)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postUnsubscribe = (payload) =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/email/unsubscribe/${payload}`)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postAnswer = (data) =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/answer`, data)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postForgotPassword = (email) =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/forgot-password`, email)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });

export const postResetPassword = (data) =>
    new Promise((resolve, reject) => {
        return axios
            .post(`${host}/reset-password`, data)
            .then((result) => resolve(result.data))
            .catch((result) => reject(result));
    });
