import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo_lo_color.png";
import arrow_right_white from "../../../assets/images/icons/arrow_right_white.svg";

export default function InfoAction({ title, text, primaryActionButton, secondaryActionText }) {
    function createMarkup() {
        return { __html: text };
    }

    return (
        <div>
            <img className="logo" src={logo} alt="logo" />
            <h1>{title}</h1>
            <p dangerouslySetInnerHTML={createMarkup()}></p>
            {primaryActionButton && (
                <Link to={primaryActionButton.link} className="btn btn-primary kingkong">
                    {primaryActionButton.text}
                    <img src={arrow_right_white} alt="arrow_right_white" />
                </Link>
            )}
            {secondaryActionText && <p dangerouslySetInnerHTML={{ __html: secondaryActionText }}></p>}
        </div>
    );
}
