import React from 'react'
import ReactSelect from 'react-select'

export default function MultiSelect({ name, options, onChange }) {
    return (
        <ReactSelect
            name={name}
            classNamePrefix='custom_select'
            className="custom_select form-control"
            placeholder=""
            isMulti={true}
            onChange={onChange}
            options={options}
            noOptionsMessage={() => "Geen opties"}
        />
    )
}
